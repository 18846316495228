import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import css from "./ConnectStep.module.css";
import * as actionTypes from "../../../../../../store/actions";
import axios from "../../../../../../axios-bf";

import Form from "../../../../../UI/Form/Form";
import Input from "../../../../../UI/Input/Input";
import Button from "../../../../../UI/Button/Button";
import ErrorMessage from "../../../../../UI/ErrorMessage/ErrorMessage";
import loadingGif from "../../../../../../assets/images/loading.gif";

const connectStep = (props) => {
  const { t } = useTranslation("signup");
  const [data, setData] = useState({});
  const [first, setFirst] = useState(true);
  const [error, setError] = useState("");
  const [errorFields, setErrorFields] = useState({});
  const [errors, setErrors] = useState({});
  const { loading, setLoading } = props;

  useEffect(() => {
    connect();
  }, []);

  const connect = () => {
    const getCarrierId = (str) => {
      if (str) {
        const match = str.match(/^i18n::subscribe\.(\w+)\./);
        return match ? match[1] : null;
      }
      return null;
    };

    props.setCarrierStep("connecting");

    setLoading(true);
    setError(null);

    const formattedData = {};
    Object.entries(data).forEach(([k, v]) => {
      if (k.endsWith("__array")) {
        const key = k.substring(0, k.indexOf("__"));
        if (!Array.isArray(formattedData[key])) formattedData[key] = [];
        formattedData[key].push(v);
      } else {
        formattedData[k] = v;
      }
    });

    const newErrors = {};
    Array.isArray(data.fields) &&
      data.fields
        .filter((f) => f.required)
        .forEach((f) => {
          if (f.type === "multi_checkbox") {
            const one = f.options.some(
              (o) => formattedData[f.name] && formattedData[f.name].includes(o.value)
            );
            if (!one) newErrors[f.name] = true;
          } else if (!formattedData[f.name]) {
            newErrors[f.name] = true;
          }
        });

    const carrier = getCarrierId(data.message);
    const errorMessages = [];
    const fieldsInError = [];
    if (carrier && Object.keys(newErrors).length) {
      for (const field in newErrors) {
        const msg = t(`subscribe.${carrier}.${field}`, "");
        if (msg.length > 0) {
          errorMessages.push(msg);
          fieldsInError.push(field);
        }
      }
    }

    if (errorMessages.length) {
      let messageStart = t("common.validField", "Please enter a valid") + " ";
      if (errorMessages.length > 1)
        messageStart = t("common.validFields", "Please enter valid:") + " ";
      setError(messageStart + errorMessages.join(", ") + ".");
      setErrorFields(fieldsInError.reduce((acc, field) => ({ ...acc, [field]: true }), {}));
      setLoading(false);
      return;
    }

    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      setLoading(false);
      return;
    } else {
      setErrors({});
    }

    // Send connect API request
    const params = { token: props.token, ...formattedData };
    if (first) {
      params.new = true;
      setFirst(false);
    }
    axios
      .post(`/subscribe/carriers/${props.carrier.name}/connect`, params)
      .then((res) => {
        setLoading(false);
        if (res && res.data && res.data.complete && res.data.account) {
          props.setCarrierId(props.carrier.key, res.data.account);
          props.setCarrierStep(props.carrier.key, "done");
        } else if (res && res.data && res.data.fields) {
          setData(res.data);
        } else {
          // Unexpected error
          setError(t("common.unexpectedError", "We are sorry but an unexpected error occured."));
          props.setCarrierStep("connect");
        }
      })
      .catch((error) => {
        setLoading(false);
        // Connexion error
        setError(error.message);
      });
  };

  const parseI18n = (text) => {
    text = typeof text === "string" ? text : "";
    const i18nStrings = text.match(/(i18n::[^\n '"]*)/g);
    if (i18nStrings) {
      i18nStrings.forEach((s) => {
        text = text.replace(s, t(s.substr(6)));
      });
    }
    return text;
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Update the data object
    setData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    // Remove error for this field if it exists
    setErrorFields((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[name]; // Remove the error for this field
      return updatedErrors;
    });

    // Call the original onChange if it exists in props
    props.onChange && props.onChange(event);
  };

  const generateForm = (data = {}) => {
    const newForm = [];

    if (data.message) {
      newForm.push(<p key="message">{parseI18n(data.message)}</p>);
    }

    if (data.error) {
      newForm.push(<ErrorMessage key="error">{parseI18n(data.error)}</ErrorMessage>);
    }

    if (Array.isArray(data.fields)) {
      data.fields.forEach((field, i) => {
        const isError = errorFields[field.name];
        if (field.type === "checkbox") {
          if (field.required) {
            newForm.push(
              <div
                key={field.name + "_required"}
                className={[css.textDanger, css.small, isError ? "" : css.hidden].join(" ")}
              >
                {parseI18n(field.required)}
              </div>
            );
          }
          newForm.push(
            <Input
              key={field.name}
              name={field.name}
              value={field.value}
              type="checkbox"
              placeholder={parseI18n(field.label)}
              required={!!field.required}
              disabled={!!field.disabled}
              onChange={handleInputChange} // Use handleInputChange to clear error on change
              className={isError ? css.inputError : ""}
            />
          );
        } else if (field.type === "multi_checkbox") {
          if (field.label) {
            newForm.push(<p key={field.name + "_label"}>{parseI18n(field.label)}</p>);
          }
          if (field.required) {
            newForm.push(
              <div
                key={field.name + "_required"}
                className={[css.textDanger, css.small, isError ? "" : css.hidden].join(" ")}
              >
                {parseI18n(field.required)}
              </div>
            );
          }
          newForm.push(
            <div key={field.name + "_checkboxes"}>
              {field.options.map((option, j) => (
                <div key={field.name + "_checkbox_" + j} className="checkbox check-primary">
                  <Input
                    name={field.name + "__" + j + "__array"}
                    value={option.value}
                    type="checkbox"
                    placeholder={parseI18n(option.label)}
                    required={!!field.required}
                    disabled={!!option.disabled}
                    onChange={handleInputChange}
                    className={isError ? css.inputError : ""}
                  />
                </div>
              ))}
            </div>
          );
        } else if (field.type === "radio") {
          if (field.label) {
            newForm.push(
              <p key={field.name + "_label"} className={isError ? css.inputError : ""}>
                {parseI18n(field.label)}
              </p>
            );
          }
          if (field.required) {
            newForm.push(
              <div
                key={field.name + "_required"}
                className={[css.textDanger, css.small, isError ? "" : css.hidden].join(" ")}
              >
                {parseI18n(field.required)}
              </div>
            );
          }
          newForm.push(
            <div key={field.name + "_radios"}>
              {field.options.map((option, j) => (
                <div key={field.name + "_radio_" + j} className="checkbox check-primary">
                  <Input
                    name={field.name}
                    value={option.value}
                    type="radio"
                    placeholder={parseI18n(option.label)}
                    required={!!field.required}
                    disabled={!!option.disabled}
                    onChange={handleInputChange}
                    className={isError ? css.inputError : ""}
                  />
                </div>
              ))}
            </div>
          );
        } else if (field.type === "hidden") {
          newForm.push(
            <input key={field.name} type="hidden" name={field.name} value={field.default} />
          );
        } else {
          if (field.required) {
            newForm.push(
              <div
                key={field.name + "_required"}
                className={[css.textDanger, css.small, isError ? "" : css.hidden].join(" ")}
              >
                {parseI18n(field.required)}
              </div>
            );
          }
          newForm.push(
            <div key={field.name} className={isError ? css.inputError : ""}>
              <Input
                name={field.name}
                value={data[field.name] || ""}
                type={field.type === "password" ? "password" : "text"}
                placeholder={parseI18n(field.label)}
                autoComplete={field.type === "password" ? "new-password" : "off"}
                onChange={handleInputChange}
                className={isError ? css.inputError : ""}
              />
            </div>
          );
        }
      });
    }

    return newForm;
  };

  const onChange = (event) => {
    const { name, type, checked, value } = event.target;
    setData({ ...data, [name]: type === "checkbox" ? (checked ? value : "") : value });
  };

  // Error message
  let errorMessage = null;
  if (error) {
    errorMessage = <ErrorMessage>{error}</ErrorMessage>;
  }

  return (
    <div className={css.ConnectStep}>
      <Form>
        {errorMessage}
        {generateForm(data)}
      </Form>

      {loading ? (
        <div style={{ marginTop: "20px", display: "flex" }}>
          <img src={loadingGif} alt="..." style={{ width: 38, height: 38, marginRight: "20px" }} />
          <div>{t("subscribe.common.loading")}</div>
        </div>
      ) : (
        <div className={css.Button}>
          <Button onClick={() => connect()} disabled={loading}>
            {t("carriers.box.connect", "Connect")}
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCarrierStep: (key, step) => dispatch({ type: actionTypes.SET_CARRIER_STEP, key, step }),
    setCarrierId: (key, id) => dispatch({ type: actionTypes.SET_CARRIER_ID, key, id }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(connectStep);
